import { graphql, Link, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";
import ImageOpt from "./ImageOpt";
import { v4 as uuidv4 } from 'uuid';


const itemsPerRow = 6;

const SearchListing = ({ module }) => {
  const [queryParams, setQueryParams] = useState(null);
  const [searchData, setsearchData] = useState([]);

  const [next, setNext] = useState(itemsPerRow);

  const handleMoreItems = () => {
    setNext(next + itemsPerRow);
  };

  let params = {};
  if (typeof window !== "undefined") {
    params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }

  useEffect(() => {
    setQueryParams(params.q);
  }, [params,next]);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      localSearchPages {
        index
        store
      }
    }
  `);
  const results = useFlexSearch(
    queryParams,
    query.localSearchPages.index,
    query.localSearchPages.store
  );

  useEffect(() => {
    setsearchData(results);
  }, [queryParams]);

  return (
    <section
      className={`small-img-grid with-pattern px-15 py-60${
        module?.extraClass ? " " + module.extraClass : ""
      }`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap lg:mx-minus-30 mx-0 gap-y-14">
          {searchData?.length > 0 &&
            searchData.slice(0, next).map((item) => (
              <div key={uuidv4()} className={`lg:w-4/12 w-full lg:px-15 px-0 fade-ani ${item.title == 'Guest Enquiry' ? 'hidden' : '' }`}>
                <div className="ig-bx">
                  {item.featuredImage && (
                    <ImageOpt
                      imgData={item.featuredImage?.node.mediaItemUrl}
                      imgAlt={item.featuredImage?.node?.altText}
                      imgClass="w-full"
                      width={432}
                      height={420}
                    />
                  )}
                  <div className="ig-info p-30 lgsccreen:p-15">
                    {item.type === "WpDestination" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                       Destination
                      </span>
                    )}

                    {item.type === "WpPost" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                        Blogs
                      </span>
                    )}
                    {item.type === "WpExperience" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                        Experiences
                      </span>
                    )}
                    {item.type === "WpTrip" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                        Trips
                      </span>
                    )}
                    {item.type === "WpTravel" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                        Travel Styles
                      </span>
                    )}
                    {item.type === "WpTeam" && (
                      <span className="text-12 text-black-500 font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                        Team
                      </span>
                    )}

                    <div className="title-black">
                      {item?.title && <h6 className="">{item.title}</h6>}
                      
                      {item.type !== "WpTeam" ? (
                        <div className="content">
                          {parse(item.excerpt && item.excerpt)}
                        </div>
                      ) : (
                        <div className="content">
                          {parse(item.content && item.content)}
                        </div>
                      )}

                      {item?.uri && (
                        <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                        {item.type !== "WpTeam" ? (
                          <Link to={item.uri} className="button btn-red-border">
                            Read More
                          </Link>
                        ) : (
                          <Link to="/team/" className="button btn-red-border">
                            Read More
                          </Link>
                        )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {next < searchData?.length && (
          <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-100 mt-30 fade-ani">
            <button className="button btn-border" onClick={handleMoreItems}>
              <span>Load More Results</span>
              <img src="/images/btn-red-bg.png" loading="eager" alt="Load More Button" />
            </button>
          </div>
        )}
         {searchData?.length === 0 &&
          <div className="w-[856px] m-auto text-center lgscreen:w-full px-20 content fade-ani">
            <div className="title-black">
              <h3>Can't find what you're looking for?</h3>
            </div>
            <p className="">Please try a different search term or return to our homepage.</p>
            <Link to="/" className="button header-btn-red mt-20">
                Go Back Home
            </Link>
          </div>
         }
      </div>
    </section>
  );
};

export default SearchListing;
export const SearchListingFragment = graphql`
  fragment SearchListingFragment on WpPage_Pagecontent_PageContent_SearchListing {
    id
    hideSection
    extraClass
  }
`;
