import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const SimpleFullImageWithContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`bg-img-content relative mdscreen:h-[380px] ${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      {module?.image && (
        <ImageOpt
          imgData={module.image.mediaItemUrl}
          imgClass="object-cover w-full mdscreen:h-[380px]"
          width={1440}
          height={720}
          imgAlt={module.image?.altText}
        />
      )}
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className={`fade-ani ${
          module?.contentPosition === 'right'
            ? 'absolute bottom-70 text-right right-70 z-9 mdscreen:right-0 mdscreen:bottom-30 mdscreen:px-20'
            : 'absolute bottom-70 left-70 px-60 z-9 mdscreen:left-0 mdscreen:bottom-30 mdscreen:px-20'
        }`}
      >
        <motion.div
          variants={item}
          className="home-banner__logo flex w-full justify-start pb-10 lgscreen:pb-0"
        >
          <img
            src="/images/logo-white.png"
            alt="Timeless Africa Safaris image"
            height={150}
            width={150}
            className="mdscreen:h-[120px] mdscreen:w-[120px] translate-x-[-10px] object-contain"
          />
        </motion.div>
        {module?.heading && (
          <motion.div variants={item} className="title-white">
            <h2>{module.heading}</h2>
          </motion.div>
        )}
        {module?.description && (
          <motion.div variants={item} className="content white text20">
            {parse(module.description)}
          </motion.div>
        )}
        {module?.link && (
          <motion.div
            variants={item}
            className={`btn-custom mt-30 relative z-9 ${
              module?.contentPosition === 'right'
                ? ' btn-custom flex flex-wrap items-start justify-end space-x-8 mt-20'
                : ''
            }`}
          >
            <Link
              to={module.link?.url}
              className={`button ${
                !module?.buttonStyle || module?.buttonStyle === 'filled'
                  ? 'header-btn-red'
                  : 'banner-btn-transparent'
              }`}
            >
              {module.link?.title}
            </Link>
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export default SimpleFullImageWithContent;
export const SimpleFullImageWithContentFragment = graphql`
  fragment SimpleFullImageWithContentFragment on WpPage_Pagecontent_PageContent_SimpleFullImageWithContent {
    id
    contentPosition
    description
    extraClass
    heading
    hideSection
    image {
      altText
      mediaItemUrl
    }
    link {
      target
      title
      url
    }
  }
`;

export const DestinationSimpleFullImageWithContentFragment = graphql`
  fragment DestinationSimpleFullImageWithContentFragment on WpDestination_Destinationcontent_DestinationContent_SimpleFullImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    contentPosition
    link {
      target
      title
      url
    }
    buttonStyle
    image {
      altText
      mediaItemUrl
    }
  }
`;
export const ExperienceSingleSimpleFullImageWithContentFragment = graphql`
  fragment ExperienceSingleSimpleFullImageWithContentFragment on WpExperience_Experiencecontent_Experience_SimpleFullImageWithContent {
    id
    contentPosition
    description
    extraClass
    heading
    hideSection
    image {
      altText
      mediaItemUrl
    }
    link {
      target
      title
      url
    }
  }
`;
export const TeamSingleSimpleFullImageWithContentFragment = graphql`
  fragment TeamSingleSimpleFullImageWithContentFragment on WpTeam_Teamcontent_TeamContent_SimpleFullImageWithContent {
    id
    contentPosition
    description
    extraClass
    heading
    hideSection
    image {
      altText
      mediaItemUrl
    }
    link {
      target
      title
      url
    }
  }
`;
export const NavigationContent_SimpleFullImageWithContent = graphql`
  fragment NavigationContent_SimpleFullImageWithContent on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_SimpleFullImageWithContent {
    id
    contentPosition
    description
    extraClass
    heading
    hideSection
    image {
      altText
      mediaItemUrl
    }
    link {
      target
      title
      url
    }
  }
`;
