import { motion, useInView } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";

const customStylesVideo = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "0",
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
    zindex: "999",
  },
};

const HomeBanner = ({ module }) => {
  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);
  const [destination, setDestination] = React.useState("");
  const [travel, setTravel] = React.useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== "undefined") {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  function openModalVideo() {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
  }
  function closeModalVideo() {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
  }

  const shortVideoUrl = module.shortVideoUrl;
  const imageFallback = module.backgroundImage.mediaItemUrl;
  const imgFallbackCDN = `https://wsrv.nl/?url=${imageFallback}&w=1495&h=800&fit=cover&output=webp`;

  const [background, setBackground] = useState(imageFallback);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    const video = document.getElementById("hero-video");
    if (video) {
      video.onloadeddata = () => {
        setVideoLoaded(true);
      };
    }
  }, [videoLoaded]);

  const query = useStaticQuery(graphql`
    query {
      options: allWpDestinationCategory {
        nodes {
          id
          name
        }
      }
      travelOptions: allWpTravel(
        filter: {
          travelStyleContent: { displayTravelOnHomePage: { eq: "yes" } }
        }
      ) {
        nodes {
          id
          title
        }
      }
      destinations: allWpDestination(
        sort: { order: DESC, fields: title }
        filter: { wpParent: { node: { id: { eq: null } } } }
      ) {
        nodes {
          id
          title
          wpChildren {
            nodes {
              id
              slug
              ... on WpDestination {
                id
                title
              }
            }
          }
        }
      }
    }
  `);
  var destinationValues =
    query.destinations.nodes.flatMap(({ wpChildren }) =>
      wpChildren.nodes.map((item) => ({ id: item.id, title: item.title }))
    ) || [];
  destinationValues = destinationValues.sort(function (a, b) {
    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
    const nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return (
    <>
      <section
        style={{ backgroundColor: `#f6f4f1` }}
        className={`h-[100vh] mdscreen:h-[80vh] lg:min-h-[550px] relative ${
          module?.extraClass ? " " + module?.extraClass : ""
        }`}
      >
        {module?.extraClass === "home-banner" && (
          <motion.div
            variants={item}
            className="home-banner__logo lgscreen:hidden absolute 2xl:right-[12%] 2xl:top-[16%] xl:top-[16%] lg:top-[25%] lg:left-[86%] h-[150px] w-[120px] z-[99]"
          >
            <img
              src="/images/836TAS23LOGO-01.png"
              alt="Timeless Africa Safaris image"
              height={200}
              width={200}
              className="h-[150px] w-[150px] object-contain"
            />
          </motion.div>
        )}
        {module?.extraClass === "home-banner" && (
          <motion.div
            variants={item}
            className="elephant"
          >
            <img
              src="/images/TA-Elephant-white-1.png"
              alt="Timeless Africa Safaris image"
              height={800}
              width={800}
              className="w-full invert opacity-[40%] object-contain"
            />
          </motion.div>
        )}
        <motion.div className="lg:absolute 2xl:top-[28%] xl:top-[26%] lg:top-[33%] 2xl:left-[7%] xl:left-[10%] lg:left-[5%]">
          {module?.heading && (
            <motion.div
              variants={item}
              className="hero-banner-text relative z-99 2xl:text-[5em] xl:text-45 mdscreen:text-26 lgscreen:text-35 lg:leading-60 xlscreen:leading-45 mdscreen:leading-27 lgscreen:mb-5"
            >
              {parse(module.heading)}
            </motion.div>
          )}
        </motion.div>
        <div 
        className="banner-img ipad:h-screen ipad:w-screen flex items-end justify-start lg:h-0 smscreen2:h-full"
        style={{
          backgroundColor: `#f6f4f1`,
          backgroundImage:`url(${imgFallbackCDN})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        >
        
          <div>
            <video
              id="hero-video"
              playsInline
              autoPlay
              muted
              loop
              poster={imgFallbackCDN}
              className="responsive-home-banner lg:h-[45vw] lg:pb-[10%] h-full lgscreen:h-full lg:w-[46vw] object-cover  lgscreen:hidden  maxscreen:h-[37vw] md:pb-[7%] maxscreen:pb-[10%]"
            >
              {!videoLoaded && !isMobile && <source src={shortVideoUrl} type="video/mp4" />}
            </video>ß
          </div>
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? "show" : "hidden"}
            variants={container}
            className="banner-info px-15 text-left z-9 relative lg:pl-70 pb-120 w-full lg:w-auto lg:max-w-[850px] mdscreen:pb-[160px]"
          >
            {module?.extraClass === "home-banner" && (
              <motion.div
                variants={item}
                className="home-banner__logo hidden lgscreen:flex relative left-10 bottom-30 w-full justify-start lgscreen:justify-center pb-20 lgscreen:pb-0 mdscreen:pt-40"
              >
                <img
                  src="/images/logo-white.png"
                  alt="Timeless Africa Safaris image"
                  height={150}
                  width={150}
                  className="mdscreen:h-[120px] ipad:top-0 relative mdscreen:w-[120px] object-contain"
                />
              </motion.div>
            )}
            {module?.description && parse(module?.description)}
          </motion.div>
        </div>
        <div className="banner-scroll-arrow-wrapper hidden mdscreen:flex flex-col items-center absolute bottom-[36px] right-[35%] z-[9]">
          <span className="scroll-arrow-text text-white italic font-secondary">
            Scroll To Discover
          </span>
          <img
            src={"images/scroll-down-arrow.svg"}
            loading="eager"
            alt="Timeless Africa Safaris image"
            className="banner-scroll-arrow h-[8px] mt-[7px] object-contain"
          />
        </div>
      </section>
      <React.Fragment>
        <Modal
          isOpen={modalIsOpenVideo}
          onRequestClose={closeModalVideo}
          style={customStylesVideo}
        >
          <div className="video-modal-popup h-full">
            <button
              onClick={closeModalVideo}
              className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 z-999 inline-block"
            >
              <img
                src="/images/modal-close.png"
                loading="lazy"
                className="m-auto w-20 h-20"
                alt="Close"
              />
            </button>
            <div className="modal-video h-full">
              {module?.videoUrl && module?.videoUrl !== "" ? (
                <ReactPlayer
                  className="modal-banner-video"
                  loop={true}
                  playing={true}
                  controls={true}
                  muted={true}
                  url={module.videoUrl}
                />
              ) : (
                <span>Video Not Found!</span>
              )}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default HomeBanner;
export const HomeBannerFragment = graphql`
  fragment HomeBannerFragment on WpPage_Pagecontent_PageContent_HomeBanner {
    id
    backgroundImage {
      altText
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1920
      )
      mediaItemUrl
    }
    bannerStyle
    buttonCaption
    description
    elephantImage {
      altText
      gatsbyImage(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED, width: 400)
    }
    extraClass
    heading
    hideSection
    videoUrl
    shortVideoUrl
  }
`;
