import Banner from '../components/Banner';
import BlogListing from '../components/BlogListing';
import BlogSimpleContent from '../components/BlogSimpleContent';
import ColumnContent from '../components/ColumnContent';
import ContactInfo from '../components/ContactInfo';
import DestinationsListing from '../components/DestinationsListing';
import DestinationsMap from '../components/DestinationsMap';
import ExperienceListing from '../components/ExperienceListing';
import Faq from '../components/Faq';
import FounderMember from '../components/FounderMember';
import FullVideo from '../components/FullVideo';
import BestTime from '../components/BestTime';
import Gallery from '../components/Gallery';
import HomeBanner from '../components/HomeBanner';
import IconWithContent from '../components/IconWithContent';
import ImageBox from '../components/ImageBox';
import ImageWithAuthorContent from '../components/ImageWithAuthorContent';
import ImageWithContent from '../components/ImageWithContent';
import ImageWithModalContent from '../components/ImageWithModalContent';
import ImageWithSliderContent from '../components/ImageWithSliderContent';
import MonthlyEvents from '../components/MonthlyEvents';
import OurNonProfitPartners from '../components/OurNonProfitPartners';
import OurPartners from '../components/OurPartners';
import PartnerListing from '../components/PartnerListing';
import PartnerTestimonial from '../components/PartnerTestimonial';
import PlanYourTrip from '../components/PlanYourTrip';
import PopularBlogListing from '../components/PopularBlogListing';
import RelatedExperience from '../components/RelatedExperience';
import RelatedTravelStyle from '../components/RelatedTravelStyle';
import SearchBanner from '../components/SearchBanner';
import SearchListing from '../components/SearchListing';
import SimpleContent from '../components/SimpleContent';
import SimpleContentWithIcon from '../components/SimpleContentWithIcon';
import SimpleFullImageWithContent from '../components/SimpleFullImageWithContent';
import TeamListing from '../components/TeamListing';
import TermsAndConditionsContent from '../components/TermsAndConditionsContent';
import TimelessNews from '../components/TimelessNews';
import TravelStyleListing from '../components/TravelStyleListing';
import TripInspirationListing from '../components/TripInspirationListing';
import { graphql } from 'gatsby';
import React from 'react';

import RelatedBlog from '../components/RelatedBlog';
import ExperienceSlider from '../components/ExperienceSlider';
import GuestFeedbacks from '../components/GuestFeedbacks';
import PopularDestination from '../components/PopularDestination';
import RelatedTripInspiration from '../components/RelatedTripInspiration';
import TestimonialListing from '../components/TestimonialListing';
import Newsletters from '../components/Newsletters';

const Main = ({ modules, parentId, location }) => {
  const components = {
    Banner,
    ImageWithAuthorContent,
    IconWithContent,
    PopularDestination,
    SimpleFullImageWithContent,
    OurPartners,
    GuestFeedbacks,
    RelatedExperience,
    RelatedTripInspiration,
    ExperienceSlider,
    RelatedBlog,
    DestinationsMap,
    DestinationsListing,
    HomeBanner,
    SimpleContent,
    ExperienceListing,
    RelatedTravelStyle,
    TravelStyleListing,
    TripInspirationListing,
    SearchBanner,
    SearchListing,
    ImageWithModalContent,
    TimelessNews,
    ImageBox,
    OurNonProfitPartners,
    ImageWithContent,
    ImageWithSliderContent,
    PartnerListing,
    PartnerTestimonial,
    Gallery,
    ColumnContent,
    SimpleContentWithIcon,
    FullVideo,
    FounderMember,
    TeamListing,
    MonthlyEvents,
    Faq,
    BlogSimpleContent,
    BlogListing,
    ContactInfo,
    PlanYourTrip,
    TermsAndConditionsContent,
    PopularBlogListing,
    TestimonialListing,
    Newsletters,
    BestTime,
  };

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === 'no'
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpPage_Pagecontent_PageContent_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  parentId,
                  location,
                })
              );
            })
        : null}
    </>
  );
};

export default Main;
export const MainModulesFragment = graphql`
  fragment MainModulesFragment on WpPage {
    pageContent {
      pageContent {
        __typename
        ...HomeBannerFragment
        ...BannerFragment
        ...ImageWithAuthorContentFragment
        ...IconWithContentFragment
        ...PopularDestinationFragment
        ...SimpleFullImageWithContentFragment
        ...OurPartnersFragment
        ...GuestFeedbacksFragment
        ...RelatedExperienceFragment
        ...RelatedTripInspirationFragment
        ...ExperienceSliderFragment
        ...RelatedBlogFragment
        ...DestinationsMapFragment
        ...DestinationsListingFragment
        ...ExperienceListingSimpleContentFragment
        ...ExperienceListingFragment
        ...RelatedTravelStyleFragment
        ...TravelStyleListingFragment
        ...TripInspirationListingFragment
        ...SearchBannerBannerFragment
        ...ImageWithModalContentFragment
        ...TimelessNewsFragment
        ...ImageBoxFragment
        ...OurNonProfitPartnersFragment
        ...ImageWithContentFragment
        ...ImageWithSliderContentFragment
        ...PartnerListingFragment
        ...SearchBannerBannerFragment
        ...PartnerTestimonialFragment
        ...GalleryFragment
        ...ColumnContentFragment
        ...SimpleContentWithIconFragment
        ...FullVideoFragment
        ...FounderMemberFragment
        ...TeamListingFragment
        ...MonthlyEventsFragment
        ...FaqPageFaqFragment
        ...BlogSimpleContentFragment
        ...BlogListingFragment
        ...ContactInfoFragment
        ...PlanYourTripFragment
        ...TermsAndConditionsContentFragment
        ...PopularBlogListingFragment
        ...TestimonialListingFragment
      }
    }
  }
`;
