import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";

const SearchBanner = ({ module, location, pageTitle, parentId }) => {
  const [gallery, setGallery] = useState([]);
  const [queryParams, setQueryParams] = useState(null);

  let params = {};
  if (typeof window !== "undefined") {
    params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }

  useEffect(() => {
    setQueryParams(params.q);
  }, [params]);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      localSearchPages {
        index
        store
      }
    }
  `);
  const results = useFlexSearch(
    queryParams,
    query.localSearchPages.index,
    query.localSearchPages.store
  );

  useEffect(() => {
    setGallery(results);
  }, [queryParams]);

  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);

  function openModalVideo() {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
  }
  function closeModalVideo() {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
  }

  return (
    <>
      <section
        className={`banner h-screen relative${
          module?.extraClass ? " " + module?.extraClass : ""
        }`}
      >
        <div className="banner-img h-full flex items-end justify-start">
          {module?.backgroundImage && (
            <GatsbyImage
              image={getImage(module?.backgroundImage)}
              alt={module?.backgroundImage?.altText}
              className="img"
            />
          )}
          {module?.elephantImage && (
            <div className="banner-second-img absolute bottom-0 left-0">
              <GatsbyImage
                image={getImage(module?.elephantImage)}
                alt={module?.elephantImage?.altText}
                className="w-[400px] object-contain mdscreen:w-full"
              />
            </div>
          )}
          <div className="search-count absolute z-9 bottom-100 left-100  font-heading">
            <h2 className="text-white fade-ani">{gallery.length} results for </h2>
            <h3 className="text-white ">“{queryParams}”</h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchBanner;
export const SearchBannerFragment = graphql`
  fragment SearchBannerBannerFragment on WpPage_Pagecontent_PageContent_SearchBanner {
    id
    backgroundImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
    }
    elephantImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 400)
      mediaItemUrl
    }
  }
`;
